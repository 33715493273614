import React, { useState, useEffect } from "react";
import {
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
} from "@ionic/react";
//import "./Login.scss";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../../data/connect";
import { claimVoucherCode } from "../../data/user/user.actions";

interface OwnProps {}

interface DispatchProps {
  claimVoucherCode: typeof claimVoucherCode;
}

interface StateProps {
  voucherCodeResult: boolean;
}

interface DemoLoginProps extends OwnProps, StateProps, DispatchProps {}

const LifetimeMemberControl: React.FC<DemoLoginProps> = ({
  claimVoucherCode,
  voucherCodeResult,
}) => {
  const [voucherCode, setVoucherCode] = useState("");

  useEffect(() => {
    if (voucherCodeResult) {
      window.location.reload();
    }
  }, [voucherCodeResult]);

  const submitCode = async () => {
    await claimVoucherCode(voucherCode);
    //console.log(`Code submitted! ${voucherCode}`);
  };

  return (
    <>
      <IonList className="login-block">
        <IonItem>
          <IonText>
            <b>
              Do you have a voucher code allowing a lifetime premium membership?
              Enter it below.
            </b>
          </IonText>
        </IonItem>
        <IonItem>
          <IonLabel>Voucher code:</IonLabel>
          <IonInput
            value={voucherCode}
            placeholder="Code"
            onIonChange={(e) => setVoucherCode(e.detail.value!)}
          ></IonInput>
          <IonButton
            disabled={voucherCodeResult}
            fill="solid"
            //color="tertiary"
            onClick={() => submitCode()}
          >
            Submit!
          </IonButton>
        </IonItem>
        <IonText hidden={!voucherCodeResult} color="success">
          Congratulations! You now have a premium account!
        </IonText>
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    voucherCodeResult: state.user.voucherCodeResult,
  }),
  mapDispatchToProps: {
    claimVoucherCode,
  },
  component: React.memo(LifetimeMemberControl),
});
