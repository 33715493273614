import React, { useState, useEffect } from "react";
import {
  //IonHeader,
  //IonToolbar,
  //IonTitle,
  IonContent,
  IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonToast,
} from "@ionic/react";
import "./Login.scss";
//import { LoginDetails } from "../models/User";
import { forgotPassword, clearError } from "../data/user/user.actions";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { RouteComponentProps } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
    forgotPassword: typeof forgotPassword;
  clearError: typeof clearError;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface ForgotPasswordProps extends OwnProps, DispatchProps, StateProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    forgotPassword,
  clearError,
  isAuthenticated,
  error,
  history,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  const [email, setEmail] = useState<string>("");
  //const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }

    if (error === "Invalid Credentials") {
      clearError();
    }
  }, [error, isAuthenticated, history]);

  const onChange = (fieldValue: string) => {
    setEmail(fieldValue);
  };

  const onClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }

    await forgotPassword(email)
    setShowToastCreated(true)
  };

  return (
    <IonPage id="forgotpassword-page">
      <IonContent>
        <div className="login-logo">
          <img src="assets/icon/Logo.png" alt="Shopping Tracker Logo" />
        </div>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => {
            setShowToastCreated(false);
          }}
          message="An email has been sent to that address"
          position="top"
          duration={5000}
          color="primary"
        />

        <form noValidate onSubmit={onClick}>
          <IonList className="login-block">
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>
              <IonInput
                name="email"
                type="email"
                value={email}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => onChange(e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && emailError && (
              <IonText color="danger">
                <p className="ion-padding-start">Email is required</p>
              </IonText>
            )}
          </IonList>

          <IonRow className="login-block">
            <IonCol>
              <IonButton type="submit" expand="block">
                Send email
              </IonButton>
            </IonCol>
          </IonRow>          
        </form>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {
    forgotPassword,
    clearError,
  },
  component: ForgotPassword,
});
