import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonItem,
  IonButton,
} from "@ionic/react";
import React, { useEffect } from "react";
//import { useParams } from "react-router";
//import { AddBtn } from "../components/layout/AddBtn";
//import { Login } from "./Login";
import { logoutUser } from "../data/user/user.actions";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { RouteComponentProps } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  logoutUser: typeof logoutUser;
}

interface StateProps {
  isAuthenticated: boolean;
  //error: string;
}

interface LoggedOutProps extends OwnProps, DispatchProps, StateProps {}

const LoggedOut: React.FC<LoggedOutProps> = ({
  logoutUser,
  isAuthenticated,
}) => {
  //const { name } = useParams<{ name: string }>();

  useEffect(() => {
    //console.log("Here");
    //console.log(isAuthenticated);
    if (isAuthenticated) {
      logoutUser();
    }
  }, [isAuthenticated]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Logged out</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Logged out!</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonTitle size="large">Goodbye!</IonTitle>
        <br />
        You have been successfully logged out
        <br />
        <IonButton href="\">Home</IonButton>
        <IonButton href="\">Login</IonButton>
        <IonButton href="\signup">Sign up</IonButton>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    //error: state.user.error,
  }),
  mapDispatchToProps: { logoutUser },
  component: LoggedOut,
});
