import React from "react";
import {
  //IonHeader,
  //IonToolbar,
  IonTitle,
  IonContent,
  //IonPage,
  //IonButtons,
  //IonMenuButton,
  //IonRow,
  //IonCol,
  //IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  //IonText,
} from "@ionic/react";
import "./Login.scss";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../../data/connect";
//import { RouteComponentProps } from "react-router";

interface OwnProps {}

interface DispatchProps {}

interface StateProps {
  isAuthenticated: boolean;
}

interface DemoLoginProps extends OwnProps, StateProps, DispatchProps {}

const DemoLoginDetails: React.FC<DemoLoginProps> = ({ isAuthenticated }) => {
  const email = "demo@shoppingtracker.app";
  const password = "123456";

  return (
    <>
      <IonList className="login-block">
        <IonItem>
          <IonTitle size="large">
            <b>
              Do you want to try it out before registering? Use the demo account
            </b>
          </IonTitle>
        </IonItem>
        <IonItem>
          <p>
            Please note that the demo account gets reset often. So if you want
            to keep your data, and not want anyone to modify or delete it, then
            please create your OWN account
          </p>
        </IonItem>
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
  }),
  mapDispatchToProps: {},
  component: React.memo(DemoLoginDetails),
});
