import React, { useEffect } from "react";
import {
  //IonHeader,
  //IonToolbar,
  //IonTitle,
  IonContent,
  IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import "./Login.scss";
import { checkEmailConfirmToken } from "../data/user/user.actions";
import { useParams } from "react-router";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { RouteComponentProps } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  checkEmailConfirmToken: typeof checkEmailConfirmToken;
}

interface StateProps {}

interface ConfirmPasswordProps extends OwnProps, DispatchProps, StateProps {}

const ConfirmPassword: React.FC<ConfirmPasswordProps> = ({
  checkEmailConfirmToken,

  history,
}) => {
  const { token } = useParams<{ token: string }>();
  //const [confirmSucceed, setConfirmSucceed] = useState(false);
  //console.log(token);

  useEffect(() => {
    //console.log(token);
    if (token) {
      checkEmailConfirmToken(token);
    }
  }, [token]);

  const onClick = (e: React.FormEvent) => {
    history.push("/");
  };

  return (
    <IonPage id="confirmemail-page">
      <IonContent>
        <div className="login-logo">
          <img src="assets/icon/Logo.png" alt="Shopping Tracker logo" />
        </div>
        <p>Thank you for confirming your email address!</p>
        <IonRow className="login-block">
          <IonCol>
            <IonButton onClick={onClick}>Continue</IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    checkEmailConfirmToken,
  },
  component: ConfirmPassword,
});
